"use strict";

jQuery(document).ready(function ($) {
  $.fn.BlogPostCarousel = function () {
    if ($('.carousel').hasClass('BlogPostsCarousel')) {
      var BlogPostCarousel = new Carousel(document.querySelector(".BlogPostsCarousel"), {
        infinite: false,
        Dots: true,
        slidesPerPage: 'auto',
        friction: 0.9,
        center: false,
        Navigation: false,
        classes: {
          container: "BlogPostsCarousel",
          viewport: "BlogPostsViewport",
          track: "BlogPostsTrack",
          slide: "BlogPostsItemHolder"
        },
        on: {
          '*': function _(instance) {
            $('.BlogPostsItemHolder').removeAttr('aria-hidden');
          }
        }
      });
      var width = $(window).width();
      $(window).on('resize', function () {
        if ($(this).width() !== width) {
          width = $(this).width();
          BlogPostCarousel.destroy();
        }
      });
    }
  };
  if ($(window).width() < 1024) {
    $(this).BlogPostCarousel();
  }
  var width = $(window).width();
  $(window).on('resize', function () {
    if ($(this).width() !== width) {
      if ($(this).width() < 1023) {
        width = $(this).width();
        $(this).BlogPostCarousel();
      }
    }
  });
});